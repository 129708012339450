import { Image } from "./image"
import { Text } from "./text"
import { Link } from "./link"
import { Download } from "./download"

export const PartnerAndCounseling = ({
  name,
  text,
  linktext,
  url,
  logo,
  phone,
  address,
  social_media_linktext,
  social_media,
  downloads,
}) => {
  return (
    <div class="partner-and-counseling">
      {name && <Text subheading={name} />}
      {logo && (
        <Image
          src={logo}
          title={name}
          alt={`Logo ${name}`}
          url={url ?? false}
        />
      )}
      {text && <Text text={text} />}
      {!logo && url && <Link url={url} linktext={linktext} />}
      {social_media && (
        <Link url={social_media} linktext={social_media_linktext} />
      )}
      {phone && <Link url={`tel:${phone}`} linktext={phone} />}
      {address && <Text text={address} />}
      {downloads && downloads.length > 0 && (
        <>
          <div>
            <strong>Downloads:</strong>
          </div>
          {downloads.map((download) => (
            <Download
              key={download}
              src={download.file}
              title={download.title}
            />
          ))}
        </>
      )}
    </div>
  )
}
