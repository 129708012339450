import { HintButton } from "./hint"

export const Textarea = (props) => {
  const { name, label } = props
  const storedObj = props.form.inFormValues({ name })

  const onChange = (e) => {
    const { value } = e.target

    // simply remove object from store if value is empty, i.e. toggle again.
    if (value == "") props.form.removeValue(name)
    else {
      props.form.toggleValue(
        {
          step: props.form.stepId,
          name,
          label,
          value,
        },
        true,
      )
    }
  }

  return (
    <div class="textarea-wrap">
      <textarea
        name={props.name}
        class={props.class}
        value={storedObj && storedObj.value}
        onChange={onChange}
        maxLength="1000"
        placeholder={props.placeholder}
      />
      {props.hint && (
        <HintButton
          title={props.hint.title}
          onClick={() => {
            props.form.openHint(props.hint)
          }}
        />
      )}
    </div>
  )
}
