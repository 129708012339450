import { useEffect, useState } from "preact/hooks"
import { ContentType } from "./contents/content-type"
import { keyEventHandling } from "../util/key-events"

import { qs } from "../util/dom"
import { Message } from "./message"

const mainEl = qs("main")
const gettext = window.gettext

export const Menu = (props) => {
  const [data, setData] = useState([]),
    [loading, setLoading] = useState(false),
    [current, setCurrent] = useState(""),
    [navShown, setNavShown] = useState(false),
    [messageData, setMessageData] = useState(undefined)

  /**
   * Load endpoint data
   */
  if (!loading && !data.length > 0) {
    setLoading(true)
    props.ep.getData("menu").then((epData) => {
      epData.onSubmit = onSubmit
      setData(epData)
      setLoading(false)
    })
  }

  const toggleNav = () => {
    setNavShown(!navShown)
    setCurrent("")
  }

  useEffect(() => {
    keyEventHandling(window, "Escape", () => {
      navShown && toggleNav()
    })
  })

  useEffect(() => {
    if (navShown) {
      setTimeout(() => {
        mainEl.style.display = "none"
      }, 400)
    } else {
      mainEl.style.display = "block"
    }
  }, [navShown])

  // messy compatibility with form.js
  const onSubmit = async (valueStore, _contactform, _redirect) => {
    const endpoint = props.contactEp

    const formData = new FormData()

    // add input values
    valueStore.map((o) => {
      formData.append(o.name, o.value)
    })

    // add honeypot value
    const hpName = "important_inputfield",
      hpValue = qs(`input[name=${hpName}]`).value
    formData.append(hpName, hpValue)

    // submit formData
    const response = await endpoint.postData(formData)

    if (response.valid) {
      setMessageData({
        message: gettext("Consultation request sent!"),
        severity: "ok",
      })
      setCurrent("")
    } else handleValidationErrors(response)
  }

  const handleValidationErrors = (response) => {
    console.log("errorResponseObject", response)
    const message = response.message || gettext("Something went wrong")
    setMessageData({
      message,
      severity: "error",
    })
  }

  return (
    <>
      <button
        class="nav-toggle open-button"
        aria-controls="nav-menu"
        onClick={toggleNav}
        aria-label="open navigation"
        disabled={navShown}
      />
      <nav
        id="nav-menu"
        class={`${navShown && "expanded"}`}
        aria-expanded={navShown ? "true" : "false"}
      >
        <div class="container">
          <button
            class="nav-toggle close-button"
            aria-controls="nav-menu"
            onClick={toggleNav}
            aria-label="close navigation"
            aria-haspopup="true"
          />
          {messageData && <Message data={messageData} />}
          {!loading &&
            data.length > 0 &&
            (current === "" ? (
              <MenuLinks {...{ data, setCurrent }} />
            ) : (
              <MenuOverlay {...{ data, current, setCurrent }} />
            ))}
        </div>
      </nav>
    </>
  )
}

export const MenuLinks = ({ data, setCurrent }) => {
  return (
    <ul role="menu">
      {data.map((link) => {
        return (
          <li key={link.id} role="none">
            {link.external ? (
              <a
                role="menuitem"
                href={link.external}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.label}
              </a>
            ) : (
              <a
                role="menuitem"
                href={`/${link.id}`}
                onClick={(e) => {
                  e.preventDefault()
                  setCurrent(link.id)
                }}
              >
                {link.label}
              </a>
            )}
          </li>
        )
      })}
    </ul>
  )
}

export const MenuOverlay = ({ data, current, setCurrent }) => {
  const page = current !== "" ? data.find((d) => d.id === current) : false

  return (
    <div class="menu-page-overlay">
      <div class="menu-backlink-wrap">
        <a
          role="menuitem"
          class="menu-backlink"
          href="/"
          onClick={(e) => {
            e.preventDefault()
            setCurrent("")
          }}
        >
          {page.backlink}
        </a>
      </div>
      {page.contents &&
        page.contents.map((obj, i) => {
          return (
            <ContentType
              {...obj}
              form={data}
              key={`menu-page-overlay-${obj.type}-${i}`}
            />
          )
        })}
    </div>
  )
}
