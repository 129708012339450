import { Text } from "./text"

const gettext = window.gettext

export const Summary = (props) => {
  const missingInputText = gettext("Missing input")

  const renderInput = (section) => {
    return (
      <>
        {section.includes.map((s) => {
          return props.form.storedValues
            .filter((o) => s.id === o.name)
            .map((input, i) => {
              return (
                <button
                  key={i}
                  class={`icon ${input.value}`}
                  onClick={(e) => {
                    e.preventDefault()
                    props.form.showStep(0, input.step)
                  }}
                >
                  {input.label}
                </button>
              )
            })
        })}
        {section.includes.map((s, i) => {
          if (
            s.required &&
            !props.form.storedValues.some((o) => o.name === s.id)
          ) {
            return (
              <button
                key={i}
                class="icon red"
                onClick={(e) => {
                  e.preventDefault()
                  props.form.showStep(0, s.required)
                }}
              >
                {missingInputText}
              </button>
            )
          }
        })}
      </>
    )
  }

  return (
    <>
      {props.sections.map((section) => {
        return (
          <div key={section}>
            <Text text={section.title} class="lead" />
            <div class="summary-buttons fr-2">{renderInput(section)}</div>
          </div>
        )
      })}
    </>
  )
}
