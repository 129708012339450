export const EmergencyLinks = (props) => {
  const { emergency_links } = props
  return (
    <div class="emergency emergency-links">
      <h3>{props.title}</h3>
      {emergency_links.map((link) => {
        const external = link.url.indexOf("http") === 0
        return (
          <a
            key={link}
            class={`emergency emergency-link ${link.class ? link.class : ""}`}
            href={link.url}
            target={external ? "_blank" : ""}
            rel={external ? "noopener noreferrer" : ""}
          >
            {link.linktext}
          </a>
        )
      })}
    </div>
  )
}
