const gettext = window.gettext

export const Buttons = (props) => {
  const classes = () => {
    let classes = false
    if (props.class) classes = props.class
    else if (props.buttons && props.buttons.length > 1) classes = "grid fr-2"
    return classes
  }

  const checkDisplayCondition = () => {
    if (props.display_condition) {
      if (
        (!props.form.summaryActive &&
          props.display_condition == "summary-active") ||
        (props.form.summaryActive &&
          props.display_condition == "summary-inactive")
      )
        return false
    }
    return true
  }

  return (
    checkDisplayCondition() && (
      <buttons class={classes()}>
        {props.buttons.map((buttonProps, i) => {
          return (
            <Button
              {...buttonProps}
              form={props.form}
              key={`${props.form.stepId}-${i}`}
            />
          )
        })}
      </buttons>
    )
  )
}

export const Button = (props) => {
  const disabled = () => {
    if (props.type == "next") {
      return !props.form.requirementsMet()
    } else if (props.type == "submit" && props.function == "sendAnonymously") {
      return [
        { name: "reporter_genders" },
        { name: "reporter_age" },
        { name: "reporter_comment" },
      ].some((c) => props.form.inFormValues(c))
    } else if (typeof props.disabled !== "undefined") {
      return props.disabled
    }
    return false
  }

  const customFunctions = (e) => {
    e.preventDefault()

    if (props.onClick) props.onClick()
    else {
      switch (props.type) {
        case "submit":
          props.form.onSubmit()
          break
        case "link":
          document.location = props.href
          break
        case "external-link":
          window.open(props.href, "_blank")
          break
        case "close":
          // modal specific
          props.form.setModalData({})
          break
        default:
          // next step
          props.form.showStep(0, props.id)
      }
    }
  }

  return (
    <button
      class={props.class}
      type={props.type}
      onClick={customFunctions}
      disabled={disabled()}
    >
      {props.label}
      {disabled() && (
        <div class="requirements-help" onClick={props.form?.showHelp}>
          {gettext("Bitte alle Felder anwählen.")}
        </div>
      )}
    </button>
  )
}
