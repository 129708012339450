/* globals plausible */
export default async (data) => {
  if (typeof plausible !== "undefined") {
    window.plausible =
      window.plausible ||
      ((...args) => {
        ;(window.plausible.q = window.plausible.q || []).push(args)
      })
    plausible("pageview", {
      u: `${window.location.origin}/${document.documentElement.lang}/${data}${window.location.search}`,
    })
  }
}
