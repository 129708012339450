import { Link } from "./link"

const linkify = (input) => {
  if (!input) {
    return null
  }
  const urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
  const emailRegex = /(\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b)/gi

  const linkifyString = (text) =>
    text
      .replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`)
      .replace(emailRegex, (email) => `<a href="mailto:${email}">${email}</a>`)

  if (Array.isArray(input)) {
    return input.map((text) => linkifyString(text))
  }
  return linkifyString(input)
}

const renderContent = (content) => {
  if (Array.isArray(content)) {
    if (!content.length) return <br /> // or empty p tag (needed because of space)
    return content.map((item, index) => (
      <p key={index} dangerouslySetInnerHTML={{ __html: item }} />
    ))
  }
  return <p dangerouslySetInnerHTML={{ __html: content }} />
}

export const Text = (props) => {
  const linkifiedContent = linkify(props.text)

  return (
    <div class={`text-content ${props.class ? props.class : ""}`}>
      {props.heading && <h2>{props.heading}</h2>}
      {props.subheading && <h3>{props.subheading}</h3>}
      {linkifiedContent && renderContent(linkifiedContent)}
      {props.link && (
        <p>
          <Link {...props.link} />
        </p>
      )}
    </div>
  )
}
